import React, { useEffect, useRef, useState } from 'react';
import rama_front from '../../assets/testimonial/rama_front.jpg';

const Assessment_Table = ({update_completeness}) => {
  const Table_Head = () => {
    return (
      <thead>
        <tr>
          <th rowSpan={2} className='notosan-semi border-b border-r border-gray-300 py-2 text-nowrap sm:text-wrap'>ลำดับ</th>
          <th rowSpan={2} className='notosan-semi border-b border-r border-gray-300 py-2 text-nowrap sm:text-wrap min-w-96'>ประเด็นการประเมิน</th>
          <th colSpan={5} className='notosan-semi border-b border-gray-300 py-2 px-2 text-nowrap sm:text-wrap'>ความถี่ในการปฏิบัติเฉลี่ยต่อสัปดาห์</th>
        </tr>

        <tr>
          <th className='notosan-semi border-b border-gray-300 py-2 text-nowrap sm:text-wrap px-10'>6 - 7 วัน</th>
          <th className='notosan-semi border-b border-gray-300 py-2 text-nowrap sm:text-wrap px-10'>4 - 5 วัน</th>
          <th className='notosan-semi border-b border-gray-300 py-2 text-nowrap sm:text-wrap px-10'>3 วัน</th>
          <th className='notosan-semi border-b border-gray-300 py-2 text-nowrap sm:text-wrap px-10'>1 - 2 วัน</th>
          <th className='notosan-semi border-b border-gray-300 py-2 text-nowrap sm:text-wrap px-10'>ไม่ปฏิบัติ</th>
        </tr>
      </thead>
    )
  }

  const Table_Row_Title = ({order, title}) => {
    return (
      <tr className='border-b border-gray-300 hover:bg-gray-100'>
        <td colSpan={7} className='notosan-semi pl-3 py-1.5'>{order + '. ' + title}</td>
      </tr>
    )
  }

  const Table_Row = ({order, question, current, handle_change, handle_reset_answer}) => {
    return (
      <tr className='border-b border-gray-300 hover:bg-gray-100'>
        <td className='notosan-reg border-r border-gray-300 py-1.5 pl-3'>{order}</td>
        <td className='notosan-reg border-r border-gray-300 py-1.5 pl-3'>{question}</td>

        <td className='py-1.5'>
          <div className='w-full h-fit flex justify-center'>
            <input type='radio' name={'Q' + order} checked={current === 0}
            className='border-gray-300 accent-mahidol-blue cursor-pointer'
            onChange={() => handle_change(0)}
            onClick={() => {
              if(current === 0) {
                handle_reset_answer();
              }
            }}></input>
          </div>
        </td>

        <td className='py-1.5'>
          <div className='w-full h-fit flex justify-center'>
            <input type='radio' name={'Q' + order} checked={current === 1}
            className='border-gray-300 accent-mahidol-blue cursor-pointer'
            onChange={() => handle_change(1)}
            onClick={() => {
              if(current === 1) {
                handle_reset_answer();
              }
            }}></input>
          </div>
        </td>

        <td className='py-1.5'>
          <div className='w-full h-fit flex justify-center'>
            <input type='radio' name={'Q' + order} checked={current === 2}
            className='border-gray-300 accent-mahidol-blue cursor-pointer'
            onChange={() => handle_change(2)}
            onClick={() => {
              if(current === 2) {
                handle_reset_answer();
              }
            }}></input>
          </div>
        </td>

        <td className='py-1.5'>
          <div className='w-full h-fit flex justify-center'>
            <input type='radio' name={'Q' + order} checked={current === 3}
            className='border-gray-300 accent-mahidol-blue cursor-pointer'
            onChange={() => handle_change(3)}
            onClick={() => {
              if(current === 3) {
                handle_reset_answer();
              }
            }}></input>
          </div>
        </td>

        <td className='py-1.5'>
          <div className='w-full h-fit flex justify-center'>
            <input type='radio' name={'Q' + order} checked={current === 4}
            className='border-gray-300 accent-mahidol-blue cursor-pointer'
            onChange={() => handle_change(4)}
            onClick={() => {
              if(current === 4) {
                handle_reset_answer();
              }
            }}></input>
          </div>
        </td>
      </tr>
    )
  }

  const [ question1, setQuestion1 ] = useState([
    {
      order: '1.1',
      question: 'มีการวางแผนค่าใช้จ่าย',
      answer_index: -1,
    },
    {
      order: '1.2',
      question: 'มีเงินเหลือเพื่อเก็บออม',
      answer_index: -1,
    },
    {
      order: '1.3',
      question: 'คิดหาวิธีในการประหยัดค่าใช้จ่าย',
      answer_index: -1,
    },
    {
      order: '1.4',
      question: 'ดูแลรักษาสิ่งของในบ้าน/โรงเรียนให้สามารถใช้ได้นาน',
      answer_index: -1,
    },
    {
      order: '1.5',
      question: 'นำของที่ใช้แล้วมาดัดแปลงใช้ประโยชน์อีก',
      answer_index: -1,
    },
    {
      order: '1.6',
      question: 'ซื้อของตามความต้องการของตนเอง แม้ไม่จำเป็นต้องใช้ในชีวิตประจำวัน',
      answer_index: -1,
    },
    {
      order: '1.7',
      question: 'ยืมเงินเพื่อนเมื่อเงินตนเองมีไม่เพียงพอ',
      answer_index: -1,
    },
  ]);

  const [ question2, setQuestion2 ] = useState([
    {
      order: '2.1',
      question: 'ข้ามถนนตรงทางม้าลายหรือข้ามสะพานลอย',
      answer_index: -1,
    },
    {
      order: '2.2',
      question: 'ส่งงาน/การบ้านที่ครูให้ไว้ได้ทันตามกำหนดเวลา',
      answer_index: -1,
    },
    {
      order: '2.3',
      question: 'ทิ้งเศษกระดาษหรือขยะลงในที่ทิ้งขยะเท่านั้น',
      answer_index: -1,
    },
    {
      order: '2.4',
      question: 'แต่งกายถูกต้องตามระเบียบของโรงเรียน',
      answer_index: -1,
    },
    {
      order: '2.5',
      question: 'เข้าโรงเรียนสาย',
      answer_index: -1,
    },
    {
      order: '2.6',
      question: 'ตั้งใจเรียนโดยไม่เล่นมือถือเลย',
      answer_index: -1,
    },
    {
      order: '2.7',
      question: 'ทานอาหารตามรสชาติที่ตนเองชอบมากกว่าตามหลักโภชนาการ',
      answer_index: -1,
    },
    {
      order: '2.8',
      question: 'ออกกำลังกายเป็นประจำ',
      answer_index: -1,
    },
    {
      order: '2.9',
      question: 'สูบบุหรี่/บุหรี่ไฟฟ้าทุกประเภท',
      answer_index: -1,
    },
    {
      order: '2.10',
      question: 'ใช้ไหมขัดฟันควบคู่ไปกับการแปรงฟันทุกครั้ง',
      answer_index: -1,
    },
  ]);

  const [ question3, setQuestion3 ] = useState([
    {
      order: '3.1',
      question: 'ไม่หยิบฉวยของผู้อื่นโดยไม่ได้รับอนุญาต',
      answer_index: -1,
    },
    {
      order: '3.2',
      question: 'พูดโกหกเพื่อรักษาผลประโยชน์ของตัวเอง',
      answer_index: -1,
    },
    {
      order: '3.3',
      question: 'คืนเงินให้พ่อค้าแม่ค้าที่ทอนเงินเกิน',
      answer_index: -1,
    },
    {
      order: '3.4',
      question: 'นำสิ่งของมีค่าที่ผู้อื่นที่ลืมหรือทำตกไว้ส่งคุณครูเพื่อตามหาเจ้าของ',
      answer_index: -1,
    },
    {
      order: '3.5',
      question: 'ยอมรับผิดทุกครั้งเมื่อกระทำความผิดจริง',
      answer_index: -1,
    },
    {
      order: '3.6',
      question: 'เมื่อเพื่อนทำความผิด ก็จะช่วยเพื่อนปกปิดเป็นความลับ',
      answer_index: -1,
    },
    {
      order: '3.7',
      question: 'ลอกงานหรือการบ้านจากเพื่อนส่งครู',
      answer_index: -1,
    },
    {
      order: '3.8',
      question: 'ไม่กล่าวโทษเพื่อนหรือผู้อื่นเพื่อให้ตนพ้นผิด',
      answer_index: -1,
    },
  ]);

  const [ question4, setQuestion4 ] = useState([
    {
      order: '4.1',
      question: 'ช่วยแนะนำถ่ายทอดความรู้ให้แก่เพื่อนด้วยความเต็มใจ',
      answer_index: -1,
    },
    {
      order: '4.2',
      question: 'เมื่อเห็นคุณครูถือสิ่งของเกินกว่ากำลังจะควบคุมได้ทั้งหมดนักเรียนจะเพิกเฉยและรีบเดินหนีเสมอ',
      answer_index: -1,
    },
    {
      order: '4.3',
      question: 'ช่วยกันรักษาความสะอาดของห้องเรียนและบริเวณโรงเรียนให้สะอาดเสมอ',
      answer_index: -1,
    },
    {
      order: '4.4',
      question: 'เป็นอาสาสมัครช่วยเหลือกิจกรรมทางสังคมในกลุ่มผู้สูงอายุ ผู้ด้อยโอกาส สัตว์ป่วย หรือรักษ์สิ่งแวดล้อม เป็นต้น',
      answer_index: -1,
    },
    {
      order: '4.5',
      question: 'มีความเพียรพยายามที่จะนำชื่อเสียงมาสู่โรงเรียน',
      answer_index: -1,
    },
    {
      order: '4.6',
      question: 'เพิกเฉยในการช่วยชีวิตผู้อื่นทั้งคนและสัตว์ เมื่อพบเหตุขึ้น',
      answer_index: -1,
    },
    {
      order: '4.7',
      question: 'บริจาคสิ่งของที่ไม่ได้ใช้แล้วหรือเงินให้กับผู้ที่ขาดแคลน',
      answer_index: -1,
    },
    {
      order: '4.8',
      question: 'เรียนรู้หลักในการทำความดีตามศาสนาที่ตนเองนับถือ',
      answer_index: -1,
    },
  ]);

  const [ question5, setQuestion5 ] = useState([
    {
      order: '5.1',
      question: 'ปฏิบัติต่อผู้มีพระคุณด้วยความจริงใจ เต็มกำลัง',
      answer_index: -1,
    },
    {
      order: '5.2',
      question: 'ไม่มีเวลาดูแลผู้ปกครองเมื่อท่านเจ็บป่วย',
      answer_index: -1,
    },
    {
      order: '5.3',
      question: 'การทำงานบ้านเป็นหน้าที่ของผู้ปกครอง',
      answer_index: -1,
    },
    {
      order: '5.4',
      question: 'เห็นคุณค่าและดูแลรักษาสิ่งของที่ตนเองใช้อยู่ทุกวัน',
      answer_index: -1,
    },
    {
      order: '5.5',
      question: 'เรียนรู้ประวัติศาสตร์ที่มาของชนชาติไทย',
      answer_index: -1,
    },
    {
      order: '5.6',
      question: 'เรียนรู้ประวัติศาสตร์การเมืองการปกครอง',
      answer_index: -1,
    },
    {
      order: '5.7',
      question: 'เรียนรู้เรื่องโครงการพระราชดำริ',
      answer_index: -1,
    },
    {
      order: '5.8',
      question: 'เรียนรู้ประวัติศาสตร์ของจังหวัด',
      answer_index: -1,
    },
  ]);

  useEffect(() => {
    check_completness();
  }, [question1, question2, question3, question4, question5]);

  function check_completness() {
    const question1_complete = getIsComplete(question1);
    const question2_complete = getIsComplete(question2);
    const question3_complete = getIsComplete(question3);
    const question4_complete = getIsComplete(question4);
    const question5_complete = getIsComplete(question5);
    update_completeness(question1_complete && question2_complete && question3_complete && question4_complete && question5_complete);
  }

  function getIsComplete(completeness) {
    let result = true;

    for(let i = 0; i < completeness.length; i++) {
      const answer_index = completeness[i]["answer_index"];

      if(answer_index === -1) {
        result = false;
        break;
      }
    }

    return result;
  }

  return (
    <div className='w-fit md:w-full h-fit border border-gray-300 rounded-md'>
      <table className="table-auto w-full h-fit border-collapse">
        <Table_Head/>
        
        <tbody>
          <Table_Row_Title order={1} title={'ความพอเพียง'}/>
          {
            question1.map((x, i) => {
              return (
                <Table_Row key={i} order={x.order} question={x.question} current={x.answer_index}
                handle_change={(answer_index) => {
                  let new_result = [...question1];
                  new_result[i]["answer_index"] = answer_index;
                  setQuestion1(new_result);
                }}
                handle_reset_answer={() => {
                  let new_result = [...question1];
                  new_result[i]["answer_index"] = false;
                  setQuestion1(new_result);
                }}/>
              )
            })
          }

          <Table_Row_Title order={2} title={'ให้มีวินัย'}/>
          {
            question2.map((x, i) => {
              return (
                <Table_Row key={i} order={x.order} question={x.question} current={x.answer_index}
                handle_change={(answer_index) => {
                  let new_result = [...question2];
                  new_result[i]["answer_index"] = answer_index;
                  setQuestion2(new_result);
                }}
                handle_reset_answer={() => {
                  let new_result = [...question2];
                  new_result[i]["answer_index"] = false;
                  setQuestion2(new_result);
                }}/>
              )
            })
          }

          <Table_Row_Title order={3} title={'ความซื่อสัตย์'}/>
          {
            question3.map((x, i) => {
              return (
                <Table_Row key={i} order={x.order} question={x.question} current={x.answer_index}
                handle_change={(answer_index) => {
                  let new_result = [...question3];
                  new_result[i]["answer_index"] = answer_index;
                  setQuestion3(new_result);
                }}
                handle_reset_answer={() => {
                  let new_result = [...question3];
                  new_result[i]["answer_index"] = false;
                  setQuestion3(new_result);
                }}/>
              )
            })
          }

          <Table_Row_Title order={4} title={'จิตอาสา'}/>
          {
            question4.map((x, i) => {
              return (
                <Table_Row key={i} order={x.order} question={x.question} current={x.answer_index}
                handle_change={(answer_index) => {
                  let new_result = [...question4];
                  new_result[i]["answer_index"] = answer_index;
                  setQuestion4(new_result);
                }}
                handle_reset_answer={() => {
                  let new_result = [...question4];
                  new_result[i]["answer_index"] = false;
                  setQuestion4(new_result);
                }}/>
              )
            })
          }

          <Table_Row_Title order={5} title={'จิตอาสา'}/>
          {
            question5.map((x, i) => {
              return (
                <Table_Row key={i} order={x.order} question={x.question} current={x.answer_index}
                handle_change={(answer_index) => {
                  let new_result = [...question5];
                  new_result[i]["answer_index"] = answer_index;
                  setQuestion5(new_result);
                }}
                handle_reset_answer={() => {
                  let new_result = [...question5];
                  new_result[i]["answer_index"] = false;
                  setQuestion5(new_result);
                }}/>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

const ThaiYouth_Assessment_Form = () => {
  const [ table_completeness, setTable_completeness ] = useState(false);
  const [ active_submit, setActive_submit ] = useState(false);
  const ref_male = useRef(null);
  const ref_female = useRef(null);
  const ref_age = useRef(null);
  const ref_level = useRef(null);

  useEffect(() => {
    check_completness();
  }, [table_completeness]);

  function check_completness() {
    let active = true;

    if(ref_male.current && ref_female.current) {
      if(!ref_male.current.checked && !ref_female.current.checked) {
        active = false;
      }
    }

    if(ref_age.current) {
      if(ref_age.current.value === '') {
        active = false;
      }
    }

    if(ref_level.current) {
      if(ref_level.current.selectedIndex === 0) {
        active = false;
      }
    }

    if(!table_completeness) {
      active = false;
    }

    setActive_submit(active);
  }

  function submit_data() {
    if(active_submit) {
      console.log('submit');
    }
  }

  return (
    <div className='relative w-screen h-screen'>
      <div className='absolute top-0 left-0 w-full h-full overflow-scroll z-20'>
        <div className='w-full xl:w-11/12 h-fit bg-white rounded-none xl:rounded-md ml-auto mr-auto my-10'>
          <div className='w-full h-fit px-10 lg:px-14 xl:px-24 pt-32'>
            <p className='notosan-semi text-web-black text-2xl text-center'>แบบสอบถามการวิจัย</p>
            <div className='w-full h-fit flex justify-center my-4'>
              <p className='notosan-reg text-web-black text-center w-3/4 lg:w-1/2 text-wrap'>เรื่อง ประสิทธิผลของโปรแกรมการเรียนรู้โดยการเสริมสร้างพลังอำนาจต่อพฤติกรรมเชิงจริยธรรมตามโครงการ “เด็กไทย ใฝ่ดี” ของนักเรียนมัธยมศึกษาตอนปลายในจังหวัดสตูล (ก่อนเรียน)</p>
            </div>

            <p className='notosan-semi text-web-black text-lg'>ส่วนที่ 1 ข้อมูลส่วนบุคคล</p>
            <div className='w-full h-fit my-4'>
              <p className='notosan-reg text-web-black'>โปรดระบุเพศ</p>

              <div className='w-full h-fit flex gap-x-10'>
                <div className='w-fit h-fit flex gap-x-2'>
                  <input ref={ref_male} type='radio' name='gender' className='accent-mahidol-blue cursor-pointer' onChange={() => check_completness()}></input>
                  <p className='notosan-reg text-web-black'>ชาย</p>
                </div>

                <div className='w-fit h-fit flex gap-x-2'>
                  <input ref={ref_female} type='radio' name='gender' className='accent-mahidol-blue cursor-pointer' onChange={() => check_completness()}></input>
                  <p className='notosan-reg text-web-black'>ชาย</p>
                </div>
              </div>
            </div>

            <div className='w-full h-fit grid grid-cols-1 md:grid-cols-2 gap-5 my-4'>
              <div>
                <p className='notosan-reg text-web-black'>โปรดระบุอายุ (ปี)</p>

                <div className='w-full h-fit flex my-2'>
                  <input ref={ref_age} type='number' placeholder='โปรดระบุอายุ (ปี)' name='age'
                  className='w-full h-fit border border-gray-300 rounded-l-md px-3 py-1 outline-mahidol-blue placeholder:text-md'
                  onChange={() => check_completness()}></input>

                  <div className='w-fit h-full border border-gray-300 px-3 py-1 rounded-r-md'>ปี</div>
                </div>
              </div>

              <div>
                <p className='notosan-reg text-web-black'>โปรดระบุชั้นปี</p>

                <select ref={ref_level} name='level' className='w-full notosan-reg px-3 py-1 rounded-md border border-gray-300 cursor-pointer my-2'
                onChange={() => check_completness()}>
                    <option value={'ไม่ระบุ'}>โปรดระบุชั้นปี...</option>
                    <option value={'4'}>ชั้นมัธยมศึกษาปีที่ 4</option>
                    <option value={'5'}>ชั้นมัธยมศึกษาปีที่ 5</option>
                    <option value={'6'}>ชั้นมัธยมศึกษาปีที่ 6</option>
                </select>
              </div>
            </div>

            <hr className='w-3/4 mx-auto my-20'/>

            <p className='notosan-semi text-web-black text-lg'>ส่วนที่ 2 การประเมินพฤติกรรมเชิงจริยธรรมตามโครงการ “เด็กไทย ใฝ่ดี” ที่นักเรียนได้รับการส่งเสริมในปัจจุบันทั้งจากโรงเรียนและครอบครัว</p>
            <p className='notosan-reg text-web-black'>คำชี้แจง ให้เลือกคำตอบที่สอดคล้องกับความถี่ในการปฏิบัติเฉลี่ยของนักเรียนในปัจจุบันมากที่สุด</p>
          </div>

          <div className='w-full h-fit pl-10 pr-0 lg:px-14 xl:px-24 overflow-x-scroll md:overflow-auto'>
            <div className='w-fit md:w-full h-fit mt-4 pr-10 md:pr-0'>
              <Assessment_Table update_completeness={(completeness) => setTable_completeness(completeness)}/>
            </div>
          </div>

          <div className='w-full h-fit px-10 lg:px-14 xl:px-24 pb-32'>
            <div className='mt-10'></div>
            <p className='notosan-reg text-web-black'>ข้อคิดเห็นเพิ่มเติมอื่นๆ (ถ้ามี)</p>
            <textarea type='text' placeholder='ข้อคิดเห็นเพิ่มเติมอื่นๆ (ถ้ามี)' rows={5}
            className='w-full h-fit border border-gray-300 rounded-md px-3 py-1 outline-mahidol-blue placeholder:text-md mt-3'></textarea>

            <div className='mt-10'></div>
            <button type='button'
            className={'notosan-med w-full h-full py-3 rounded-md ' + (active_submit ? 'bg-mahidol-blue hover:bg-mahidol-yellow text-white hover:text-web-black cursor-pointer' : 'bg-gray-300 text-gray-500 cursor-not-allowed')}
            onClick={() => submit_data()}>ส่งข้อมูล</button>
          </div>
        </div>
      </div>

      <img src={rama_front} className='absolute top-0 left-0 w-full h-full object-cover z-0'/>
      <div className='absolute top-0 left-0 w-full h-full bg-black/70 z-0'></div>
    </div>
  )
}

export default ThaiYouth_Assessment_Form