import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home/Home';

import 'boxicons';
import Admin from './pages/Admin/Admin';
import Admin_news_add from './pages/Admin/Admin_news/Admin_news_add';
import News from './pages/News/News';
import Activities from './pages/Activities/Activities';
import Activities_detail from './pages/Activities/Activities_detail';
import News_detail from './pages/News/News_detail';
import Admin_activities_add from './pages/Admin/Admin_activities/Admin_activities_add';
import About from './pages/About/About';
import Team from './pages/About/Team';
import Testimonial from './pages/Testimonial/Testimonial';
import KM from './pages/KM/KM';
import Admin_testimonial from './pages/Admin/Admin_testimonial/Admin_testimonial';
import Contact from './pages/Contact/Contact';
import ThaiYouth_Assessment_Form from './pages/ThaiYouth/ThaiYouth_Assessment_Form';

const router = createBrowserRouter([
  {
    path: "/:user?",
    element: <Home/>,
  },
  {
    path: "/about/:topic?",
    element: <About/>,
  },
  {
    path: "/team",
    element: <Team/>,
  },
  {
    path: "/testimonial",
    element: <Testimonial/>,
  },
  {
    path: "/km",
    element: <KM/>,
  },
  {
    path: "/news",
    element: <News/>,
  },
  {
    path: "/news/detail/:id?",
    element: <News_detail/>,
  },
  {
    path: "/activities",
    element: <Activities/>,
  },
  {
    path: "/activities/detail/:id?",
    element: <Activities_detail/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
  {
    path: "/admin/:authorised?",
    element: <Admin/>,
  },
  {
    path: "/admin/news/add/:authorised?/:id?",
    element: <Admin_news_add/>,
  },
  {
    path: "/admin/activities/add/:authorised?/:id?",
    element: <Admin_activities_add/>,
  },
  {
    path: "/admin/testimonial/:authorised?",
    element: <Admin_testimonial/>,
  },
  {
    path: "/thaiyouth/assessment",
    element: <ThaiYouth_Assessment_Form/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
