import React, { useEffect, useState } from 'react';
import title_decorate from '../../../assets/decorate/title_decorate.svg';
import RoundFullButton from '../../Components/Button/RoundFullButton';
import HomeTestimonial_Card from './HomeTestimonial_Card';
import HomeTestimonial_CardLong from './HomeTestimonial_CardLong';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { php_list } from '../../../php/php_list';

const HomeTestimonial = () => {
  const [ select_all, setSelect_all ] = useState(true);
  const [ select_knowledge_management, setSelect_knowledge_management ] = useState(false);
  const [ select_training, setSelect_training ] = useState(false);
  const [ select_review, setSelect_review ] = useState(false);
  const [ db, setDB ] = useState([]);

  const navigate = useNavigate();

  function handle_see_all() {
    navigate("/testimonial");
  }
  
  useEffect(() => {
    let abortcontroller = new AbortController();

    axios.get(php_list.php_testimonials_read_all, { signal: abortcontroller.signal })
    .then((response) => {
        // Handle the success response
        console.log(response.data);

        if (response.data.success) {
            let format_db = [...response.data.results];

            for(let i = 0; i < format_db.length; i++) {
                let founder = format_db[i].founders.split(",");
                format_db[i].founder = founder;

                if(i === format_db.length - 1) {
                    setDB([...format_db]);
                }
            }
        } 
        else {
            console.log('Error loading data: ' + response.data.message);
        }
    })
    .catch((error) => {
        console.log(error);
    });

    return () => abortcontroller.abort();
  }, []);

  function reset_category() {
    setSelect_all(false);
    setSelect_knowledge_management(false);
    setSelect_training(false);
    setSelect_review(false);
  }

  function handle_select_all() {
      reset_category();
      setSelect_all(true);
  }

  function handle_select_knowledge_management() {
      reset_category();
      setSelect_knowledge_management(true);
  }

  function handle_select_training() {
      reset_category();
      setSelect_training(true);
  }

  function handle_select_review() {
      reset_category();
      setSelect_review(true);
  }

  return (
    <div className='w-full h-fit py-10'>
        <div className='w-full h-fit flex flex-col justify-center my-6'>
            <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ผลงานของเรา</h3>
            <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
        </div>

        <div className='hidden w-full h-fit justify-center gap-2.5 sm:gap-5'>
            <p className={'notosan-reg cursor-pointer hover:text-web-gold ' + (select_all ? 'text-web-gold' : 'text-web-black-secondary')} onClick={() => handle_select_all()}>ทั้งหมด</p>
            <p className='cursor-default'>/</p>
            <p className={'notosan-reg cursor-pointer hover:text-web-gold ' + (select_knowledge_management ? 'text-web-gold' : 'text-web-black-secondary')} onClick={() => handle_select_knowledge_management()}>การจัดการความรู้</p>
            <p className='cursor-default'>/</p>
            <p className={'notosan-reg cursor-pointer hover:text-web-gold ' + (select_training ? 'text-web-gold' : 'text-web-black-secondary')} onClick={() => handle_select_training()}>การฝึกอบรม</p>
            <p className='cursor-default'>/</p>
            <p className={'notosan-reg cursor-pointer hover:text-web-gold ' + (select_review ? 'text-web-gold' : 'text-web-black-secondary')} onClick={() => handle_select_review()}>ถอดบทเรียนความสำเร็จ</p>
        </div>

        { /* Display on large screen */ }
        <div className='hidden md:grid w-auto h-fit grid-cols-3 gap-2 sm:gap-5 mx-10 sm:mx-20 md:mx-28 my-8 sm:my-14'>
            {
                db.map((x, i) => {
                    return (
                        <HomeTestimonial_Card key={i} info={x} custom={i%3}/>
                    )

                    /*
                    if(i === 0 || i === 1) {
                        return (
                            <HomeTestimonial_Card key={i} info={x} custom={i%3}/>
                        )
                    }

                    if(i === 2) {
                        return (
                            <HomeTestimonial_CardLong key={i} info={x} custom={i%3}/>
                        )
                    }

                    if(i === 3) {
                        return (
                            <HomeTestimonial_Card key={i} info={x} custom={i%3}/>
                        )
                    }

                    if(i === 4) {
                        return (
                            <HomeTestimonial_CardLong key={i} info={x} custom={i%3}/>
                        )
                    }

                    if(i === 5) {
                        return (
                            <HomeTestimonial_Card key={i} info={x} custom={i%3}/>
                        )
                    }

                    if(i === 6) {
                        return (
                            <HomeTestimonial_CardLong key={i} info={x} custom={i%3}/>
                        )
                    }

                    if(i === 7 || i === 8) {
                        return (
                            <HomeTestimonial_Card key={i} info={x} custom={i%3}/>
                        )
                    }
                    */
                })
            }
        </div>

        { /* Display on mobile screen */ }
        <div className='block md:hidden mx-10 sm:mx-20 md:mx-28 my-8 sm:my-14'>
            {
                db.map((x, i) => {
                    if(i <= 3) {
                        return (
                            <div className='my-6'>
                                <img src={x.image} className='w-full h-40 object-cover rounded-md'/>
                                <p className='notosan-med text-web-black mt-4'>{x.title}</p>
                                <p className='notosan-light text-web-black-secondary text-xs'>{x.founder}</p>
                            </div>
                        );
                    }
                })
            }
        </div>

        <div className='w-full h-fit flex justify-center mt-10'>
            <RoundFullButton text={'ดูทั้งหมด'} onclick={handle_see_all }/>
        </div>
    </div>
  )
}

export default HomeTestimonial