import React, { useEffect, useRef, useState } from 'react';
import './testimonial.css';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import cover from '../../assets/cover/cover_testimonial.png';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import HomeTestimonial_Card from '../Home/HomeTestimonial/HomeTestimonial_Card';
import { status_list } from './status_list';
import axios from 'axios';
import { php_list } from '../../php/php_list';

import SearchInput from '../Components/Input/SearchInput';
import { default as Web_pagination } from '../Components/Pagination/Pagination';
import { motion, useAnimationControls } from "framer-motion";
import TestimonialIntroduction from './TestimonialIntroduction';
import ModalTestimonial_detail from '../Components/Modal/ModalTestimonial/ModalTestimonial_detail';
import title_decorate from '../../assets/decorate/title_decorate.svg';
import Testimonial_Card_Favorite from './Testimonial_Card_Favorite';

const Testimonial = () => {
    const [ page, setPage ] = useState(1);
    const [ items_per_page, setItems_per_page ] = useState(21);
    const [ max_page, setMax_page ] = useState(1);

    const [ modal_is_show, setModal_is_show ] = useState(false);
    const [ modal_detail, setModal_detail ] = useState({});
    const default_items = [
        {
            image: 'smartphone',
            title: 'โครงการแอปพลิเคชันสำหรับการตรวจสุขภาพจิตออนไลน์',
            founder: ['รศ.ดร. ปกรณ์ สิทธิบูรณ์'],
            description: [
                'โครงการนี้มุ่งเน้นการพัฒนาแอปพลิเคชันออนไลน์ที่ช่วยให้ผู้ใช้สามารถประเมินสุขภาพจิตของตนเองได้อย่างรวดเร็วและแม่นยำ โดยผ่านการตอบคำถามและการให้ข้อมูลที่เกี่ยวข้อง ระบบจะประมวลผลข้อมูลและให้คำแนะนำเบื้องต้นแก่ผู้ใช้ ซึ่งสามารถนำไปปรึกษาผู้เชี่ยวชาญด้านสุขภาพจิตเพิ่มเติมได้ โดยแอปพลิเคชันนี้ได้รับการออกแบบให้ใช้งานง่ายและเป็นมิตรกับผู้ใช้ เพื่อส่งเสริมให้ประชาชนตระหนักถึงความสำคัญของสุขภาพจิต และสามารถดูแลตนเองได้ดียิ่งขึ้น',
                'นอกจากนี้ แอปพลิเคชันนี้ยังมีการเชื่อมโยงกับโรงพยาบาลรามาธิบดี และสถานพยาบาลอื่น ๆ ในประเทศไทย เพื่อให้ผู้ใช้สามารถนัดหมายและขอคำปรึกษาเพิ่มเติมจากทีมผู้เชี่ยวชาญด้านสุขภาพจิตของโรงพยาบาลได้โดยตรง โครงการนี้ยังร่วมมือกับกระทรวงสาธารณสุขในการสร้างเครือข่ายการดูแลสุขภาพจิตแบบองค์รวม ซึ่งจะช่วยให้การดูแลและรักษาสุขภาพจิตของประชาชนเป็นไปอย่างมีประสิทธิภาพและครอบคลุม',
                'การพัฒนาแอปพลิเคชันนี้ยังมีเป้าหมายในการลดภาระงานของผู้ให้บริการทางการแพทย์ โดยเฉพาะในพื้นที่ที่มีการเข้าถึงบริการทางการแพทย์จำกัด ทำให้การให้คำปรึกษาและการดูแลสุขภาพจิตสามารถทำได้ผ่านแพลตฟอร์มดิจิทัลที่สะดวกและมีประสิทธิภาพ ซึ่งจะช่วยเสริมสร้างระบบสาธารณสุขของประเทศไทยให้แข็งแกร่งยิ่งขึ้นในอนาคต',
            ],
            status: 'กำลังดำเนินการ',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2564,
                    title: "การวิจัยและพัฒนา",
                    detail: [
                      'เริ่มต้นการวิจัยและพัฒนาแอปพลิเคชันในปี 2564 โดยมีการศึกษาความต้องการและพฤติกรรมการใช้งานของกลุ่มเป้าหมาย',
                    ],
                  },
                  {
                    year: 2566,
                    title: "เปิดตัวแอปพลิเคชันรุ่นทดสอบ",
                    detail: [
                      'เปิดตัวแอปพลิเคชันรุ่นทดสอบให้กับกลุ่มผู้ใช้เบื้องต้น เพื่อรวบรวมความคิดเห็นและข้อเสนอแนะ',
                    ],
                  },
            ],
        },
        {
            image: 'vaccine',
            title: 'โครงการพัฒนาวัคซีนป้องกันโรคไวรัสโควิด-19',
            founder: [
                'ศ.นพ. สมศักดิ์ ศรีสมบูรณ์',
                'ศ.ดร. ชัยวัฒน์ อมรศิริ',
                'ดร. วรัญญู ศักดิ์ศรี',
            ],
            description: [
                'โครงการนี้มุ่งเน้นการวิจัยและพัฒนาวัคซีนที่มีประสิทธิภาพในการป้องกันการติดเชื้อไวรัสโควิด-19 โดยใช้เทคโนโลยีขั้นสูงและการทดลองทางคลินิกที่ได้มาตรฐานสากล ทีมวิจัยนำโดย ศ.นพ. สมศักดิ์ ศรีสมบูรณ์, ศ.ดร. ชัยวัฒน์ อมรศิริ, และ ดร. วรัญญู ศักดิ์ศรี ได้ทำงานร่วมกับองค์กรต่าง ๆ ทั้งในและต่างประเทศ เพื่อให้ได้วัคซีนที่ปลอดภัยและสามารถใช้งานได้อย่างแพร่หลาย ในกระบวนการพัฒนา วัคซีนต้องผ่านการทดลองทางคลินิกที่เข้มงวดหลายขั้นตอน เพื่อประเมินความปลอดภัยและประสิทธิภาพ โครงการนี้มีเป้าหมายในการหยุดยั้งการแพร่ระบาดและฟื้นฟูสุขภาพของประชากรทั่วโลก โรงพยาบาลรามาธิบดีได้มีบทบาทสำคัญในการสนับสนุนด้านวิทยาศาสตร์และทรัพยากรในการพัฒนาวัคซีนนี้ให้ประสบความสำเร็จ'
            ],
            status: 'เสร็จสิ้นการดำเนินงาน',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2563,
                    title: "การวิจัยและพัฒนาเริ่มต้น",
                    detail: [
                        'โครงการเริ่มต้นในปี 2563 โดยมีการศึกษาข้อมูลไวรัสโควิด-19 และพัฒนาโมเดลวัคซีน',
                        'ทั้งนี้ ทีมวิจัยร่วมมือกับนักวิทยาศาสตร์ชั้นนำเพื่อพัฒนาเทคโนโลยีวัคซีนที่มีประสิทธิภาพ'
                    ],
                },
                {
                    year: 2564,
                    title: "เริ่มการทดลองทางคลินิกระยะที่ 1",
                    detail: [
                        'การทดลองทางคลินิกระยะที่ 1 เริ่มต้นในต้นปี 2564 เพื่อทดสอบความปลอดภัยของวัคซีนในกลุ่มอาสาสมัคร',
                        'ผลการทดลองแสดงถึงความปลอดภัยและเริ่มแสดงประสิทธิภาพในการสร้างภูมิคุ้มกัน'
                    ],
                },
                {
                    year: 2565,
                    title: "การทดลองทางคลินิกระยะที่ 2 และ 3",
                    detail: [
                        'การทดลองระยะที่ 2 และ 3 ดำเนินการในช่วงกลางปี 2565 เพื่อทดสอบประสิทธิภาพและความปลอดภัยในกลุ่มตัวอย่างที่ใหญ่ขึ้น',
                        'มีการรวบรวมข้อมูลจากกลุ่มตัวอย่างหลากหลายเชื้อชาติและภูมิภาค'
                    ],
                },
                {
                    year: 2566,
                    title: "ขอรับการรับรองจากองค์การอาหารและยา",
                    detail: [
                        'ทีมวิจัยยื่นขอรับการรับรองจากองค์การอาหารและยา',
                    ],
                },
                {
                    year: 2567,
                    title: "การแจกจ่ายวัคซีน",
                    detail: [
                        'วัคซีนได้รับการแจกจ่ายให้กับประชากรทั่วประเทศผ่านความร่วมมือกับองค์กรด้านสุขภาพ',
                    ],
                },
            ],
        },
        {
            image: 'thermometer',
            title: 'โครงการวิจัยไข้หวัดใหญ่และการป้องกัน',
            founder: ['นพ.ประภาส สุนทรธรรม'],
            description: [
                'โครงการนี้มีเป้าหมายในการศึกษาความรุนแรงของเชื้อไวรัสไข้หวัดใหญ่และวิธีการป้องกันที่มีประสิทธิภาพ โดยเน้นการรวบรวมข้อมูลการแพร่กระจายและอาการของโรคจากกลุ่มผู้ป่วยหลากหลายทั่วประเทศ ทีมวิจัยนำโดย นพ.ประภาส สุนทรธรรม มุ่งเน้นการพัฒนาวิธีการป้องกันที่ทันสมัยและให้คำแนะนำที่เหมาะสมต่อสาธารณะ โรงพยาบาลรามาธิบดีได้เข้ามามีบทบาทสำคัญในการสนับสนุนงานวิจัยนี้ โดยจัดหาทรัพยากรและเครื่องมือทางการแพทย์ที่จำเป็น โครงการนี้มีความสำคัญในการลดการแพร่ระบาดของไข้หวัดใหญ่ และเพิ่มความรู้ความเข้าใจเกี่ยวกับโรคนี้ในสังคม ซึ่งจะช่วยเสริมสร้างสุขภาพของประชาชนไทยในระยะยาว'
            ],
            status: 'กำลังดำเนินการ',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2562,
                    title: "เริ่มต้นการวิจัย",
                    detail: [
                        'โครงการเริ่มต้นในปี 2562 ด้วยการวางแผนและการจัดทีมวิจัยที่มีความเชี่ยวชาญด้านไวรัสวิทยาและระบาดวิทยา',
                    ],
                },
                {
                    year: 2563,
                    title: "การรวบรวมข้อมูลเบื้องต้น",
                    detail: [
                        'ทีมวิจัยเริ่มเก็บรวบรวมข้อมูลจากกลุ่มผู้ป่วยในหลายภูมิภาค ทำการวิเคราะห์ข้อมูลเพื่อหาปัจจัยเสี่ยงและลักษณะการแพร่กระจายของไวรัส',
                    ],
                },
                {
                    year: 2564,
                    title: "พัฒนาวิธีการป้องกัน",
                    detail: [
                        'พัฒนาวิธีการป้องกัน โดยอิงจากข้อมูลที่ได้จากการวิจัย',
                    ],
                },
            ],
        },
        {
            image: 'mask',
            title: 'โครงการวิจัยการใช้งานหน้ากากอนามัยในโรงพยาบาล',
            founder: ['ดร.ชยพล สุวรรณรักษ์'],
            description: [
                'โครงการนี้มีวัตถุประสงค์ในการศึกษาประสิทธิภาพและการใช้งานหน้ากากอนามัยในโรงพยาบาล เพื่อป้องกันการแพร่กระจายของเชื้อโรคในสถานพยาบาล ทีมวิจัยนำโดย ดร.ชยพล สุวรรณรักษ์ ได้ทำการทดสอบคุณภาพของหน้ากากอนามัยประเภทต่างๆ รวมถึงสำรวจความตระหนักและการปฏิบัติในการใช้หน้ากากอนามัยของบุคลากรทางการแพทย์ โครงการนี้มีความสำคัญในการปรับปรุงการป้องกันการติดเชื้อภายในโรงพยาบาลและส่งเสริมการใช้งานหน้ากากอนามัยอย่างเหมาะสมและปลอดภัย ผลการวิจัยนี้จะช่วยให้โรงพยาบาลสามารถพัฒนานโยบายการใช้หน้ากากอนามัยที่เหมาะสมยิ่งขึ้น และเป็นการสนับสนุนความพยายามในการควบคุมโรคในระดับชาติ โดยความร่วมมือจากโรงพยาบาลรามาธิบดีและหน่วยงานสาธารณสุขในประเทศไทย'
            ],
            status: 'เสร็จสิ้นการดำเนินงาน',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2563,
                    title: "เริ่มต้นการวิจัยและการทดสอบ",
                    detail: [
                        'โครงการเริ่มต้นในปี 2563 โดยการรวบรวมข้อมูลเกี่ยวกับประเภทและคุณภาพของหน้ากากอนามัยที่ใช้ในโรงพยาบาล',
                        'ทีมวิจัยได้เริ่มทดสอบหน้ากากอนามัยในห้องปฏิบัติการเพื่อประเมินประสิทธิภาพในการกรองเชื้อโรค'
                    ],
                },
                {
                    year: 2564,
                    title: "การวิเคราะห์ผลการทดสอบและการสำรวจ",
                    detail: [
                        'ดำเนินการวิเคราะห์ข้อมูลที่ได้จากการทดสอบและการสำรวจ ระบุปัจจัยที่ส่งผลต่อประสิทธิภาพและความปลอดภัยของการใช้หน้ากากอนามัยในโรงพยาบาล',
                    ],
                },
                {
                    year: 2566,
                    title: "การพัฒนานโยบายการใช้หน้ากากอนามัย",
                    detail: [
                        'ผลการวิจัยถูกนำไปใช้ในการพัฒนานโยบายและแนวทางการใช้หน้ากากอนามัยในโรงพยาบาล มีการจัดทำคู่มือและการฝึกอบรมให้กับบุคลากรทางการแพทย์เกี่ยวกับการใช้งานหน้ากากอนามัยที่ถูกต้อง',
                    ],
                },
                {
                    year: 2567,
                    title: "เผยแพร่ผลการวิจัย",
                    detail: [
                        'ผลการวิจัยถูกเผยแพร่ในวารสารวิชาการและนำเสนอในงานประชุมวิชาการด้านสาธารณสุข',
                    ],
                },
            ],
        },
        {
            image: 'medicine',
            title: 'โครงการพัฒนายาใหม่สำหรับการรักษามะเร็ง',
            founder: [
                'ศ.นพ. ชัยพร ศรีสมบัติ',
                'ศ.ดร. ชัยวัฒน์ อมรศิริ',
            ],
            description: [
                'โครงการนี้มุ่งเน้นการวิจัยและพัฒนายาใหม่ที่มีประสิทธิภาพสำหรับการรักษาโรคมะเร็ง โดยใช้เทคโนโลยีที่ล้ำสมัยและนวัตกรรมทางการแพทย์ล่าสุด ทีมวิจัยนำโดย ศ.นพ. ชัยพร ศรีสมบัติ และ ศ.ดร. ชัยวัฒน์ อมรศิริ ได้ทำงานร่วมกันอย่างใกล้ชิดกับนักวิทยาศาสตร์และผู้เชี่ยวชาญจากหลายสาขา โดยเฉพาะด้านวิทยาศาสตร์ชีวภาพและเภสัชวิทยา เพื่อค้นหาสารเคมีที่มีศักยภาพในการยับยั้งการเติบโตของเซลล์มะเร็ง ในระยะเริ่มต้นของการวิจัย ทีมงานได้ระบุสารเคมีที่มีความสามารถในการลดการเจริญเติบโตของเซลล์มะเร็งในห้องปฏิบัติการ และได้คัดเลือกสารเคมีเหล่านี้เพื่อพัฒนายาต้นแบบ',
                'ในกระบวนการพัฒนายาต้นแบบ ทีมวิจัยได้ดำเนินการทดลองในห้องปฏิบัติการและการทดลองทางคลินิกเพื่อประเมินความปลอดภัยและประสิทธิภาพของยา การทดลองนี้ใช้เทคโนโลยีที่ทันสมัยในการวิเคราะห์ผลการรักษา รวมถึงการทดสอบในอาสาสมัครและกลุ่มผู้ป่วยจริง ทั้งนี้ โรงพยาบาลรามาธิบดีได้ให้การสนับสนุนอย่างมากในการดำเนินการวิจัย โดยเฉพาะด้านการจัดหาอุปกรณ์ทางการแพทย์ที่จำเป็น และการให้คำปรึกษาจากผู้เชี่ยวชาญด้านมะเร็ง',
                'แม้ว่าโครงการนี้จะถูกยกเลิกในที่สุด แต่การวิจัยและการพัฒนาที่ยาวนานได้สร้างความรู้และนวัตกรรมใหม่ๆ ที่มีศักยภาพในการนำไปต่อยอดในอนาคต ข้อมูลที่ได้จากการวิจัยนี้ยังสามารถนำไปใช้ในการปรับปรุงการรักษาและการพัฒนายาใหม่ๆ ในอนาคต ทำให้โครงการนี้มีคุณค่ามากกว่าแค่การพัฒนายาใหม่ แต่ยังเป็นการเสริมสร้างองค์ความรู้ในวงการแพทย์และเภสัชกรรม และเปิดทางให้กับการวิจัยอื่น ๆ ที่มีความสำคัญในอนาคตอีกด้วย',
            ],
            status: 'ยกเลิกโครงการ',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2565,
                    title: "เริ่มต้นการวิจัยและพัฒนายา",
                    detail: [
                      'โครงการเริ่มต้นในปี 2565 โดยทำการวิจัยและพัฒนายาต้นแบบที่มีศักยภาพในการรักษามะเร็ง',
                    ],
                },
            ],
        },
        {
            image: 'bloodtube',
            title: 'โครงการปรับปรุงกระบวนการตรวจวินิจฉัยทางห้องปฏิบัติการ',
            founder: [
                'ผศ.ดร. นฤมล สุขสันต์',
                'ศ.ดร. วิจิตร บรรจง',
                'ดร. วรัญญู ศักดิ์ศรี',
            ],
            description: [
                'โครงการนี้มีวัตถุประสงค์เพื่อพัฒนากระบวนการตรวจวินิจฉัยในห้องปฏิบัติการให้มีประสิทธิภาพและแม่นยำยิ่งขึ้น โดยใช้เทคโนโลยีขั้นสูงและระบบอัตโนมัติในการวิเคราะห์ตัวอย่าง นำโดย ผศ.ดร. นฤมล สุขสันต์, ศ.ดร. วิจิตร บรรจง, และ ดร. วรัญญู ศักดิ์ศรี ทีมวิจัยได้ทำการสำรวจและวิเคราะห์กระบวนการตรวจวินิจฉัยที่ใช้ในปัจจุบัน เพื่อระบุปัจจัยที่สามารถปรับปรุงได้ โครงการนี้มุ่งหวังที่จะลดระยะเวลาการตรวจวินิจฉัยและเพิ่มความถูกต้องของผลการตรวจ ซึ่งจะช่วยให้แพทย์สามารถให้การรักษาแก่ผู้ป่วยได้อย่างมีประสิทธิภาพและรวดเร็ว',
                'ในระหว่างการดำเนินโครงการ ทีมวิจัยได้ทำการพัฒนาและทดลองเทคโนโลยีใหม่ๆ เช่น การนำระบบปัญญาประดิษฐ์ (AI) มาใช้ในการประมวลผลข้อมูลทางห้องปฏิบัติการ ซึ่งมีศักยภาพในการวิเคราะห์ข้อมูลได้รวดเร็วและแม่นยำ นอกจากนี้ยังมีการพัฒนาระบบการจัดการข้อมูลที่เชื่อมโยงกับระบบสารสนเทศของโรงพยาบาลรามาธิบดี เพื่อให้ผลการตรวจวินิจฉัยสามารถเข้าถึงได้ง่ายและสะดวกยิ่งขึ้นสำหรับทีมแพทย์และเจ้าหน้าที่สาธารณสุข',
                'แม้ว่าการดำเนินงานของโครงการจะถูกระงับชั่วคราวเนื่องจากข้อจำกัดด้านงบประมาณและทรัพยากร แต่งานวิจัยที่ได้ดำเนินการไปแล้วมีความสำคัญในการปูทางสู่การพัฒนาระบบการตรวจวินิจฉัยที่ทันสมัยและมีประสิทธิภาพมากขึ้นในอนาคต ข้อมูลที่ได้จากการวิจัยนี้ยังสามารถนำไปใช้ในการวางแผนและพัฒนานวัตกรรมทางการแพทย์อื่นๆ ที่มีศักยภาพในการยกระดับมาตรฐานการดูแลสุขภาพของประเทศให้สูงขึ้น',
            ],
            status: 'ระงับชั่วคราว',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2565,
                    title: "การสำรวจและวิเคราะห์กระบวนการปัจจุบัน",
                    detail: [
                        'เริ่มต้นการสำรวจและวิเคราะห์กระบวนการตรวจวินิจฉัยที่ใช้ในห้องปฏิบัติการปัจจุบัน',
                    ],
                },
                {
                    year: 2566,
                    title: "พัฒนาและทดลองเทคโนโลยีใหม่",
                    detail: [
                        'ทำการทดลองและปรับปรุงเทคโนโลยีตามผลการทดสอบและข้อเสนอแนะ'
                    ],
                },
            ],
        },
        {
            image: 'salt',
            title: 'โครงการรณรงค์ลดการบริโภคเกลือเพื่อสุขภาพหัวใจ',
            founder: [
                'รศ.ดร. สุภัทรา สุขสมบัติ',
                'ดร. วรัญญู ศักดิ์ศรี',
            ],
            description: [
                'โครงการนี้มีเป้าหมายในการสร้างความตระหนักรู้เกี่ยวกับผลกระทบของการบริโภคเกลือที่มากเกินไปต่อสุขภาพหัวใจและหลอดเลือด โดยมุ่งเน้นให้ข้อมูลและคำแนะนำเกี่ยวกับการลดปริมาณเกลือในอาหาร รวมถึงส่งเสริมให้เกิดการปรับเปลี่ยนพฤติกรรมการบริโภคในชีวิตประจำวัน รศ.ดร. สุภัทรา สุขสมบัติ และ ดร. วรัญญู ศักดิ์ศรี นำทีมงานทำการวิจัยเพื่อรวบรวมข้อมูลที่สำคัญเกี่ยวกับการบริโภคเกลือในประเทศไทย และพัฒนาวิธีการสื่อสารที่มีประสิทธิภาพในการให้ข้อมูลแก่ประชาชน',
                'ทีมงานทำงานร่วมกับชุมชนและหน่วยงานด้านสุขภาพในการเผยแพร่ข้อมูลเกี่ยวกับวิธีการลดเกลือในอาหารประจำวัน เช่น การใช้เครื่องปรุงที่มีโซเดียมน้อยลง และการเลือกบริโภคอาหารสดแทนอาหารแปรรูป นอกจากนี้ยังมีการจัดกิจกรรมเพื่อสร้างการมีส่วนร่วมของประชาชน เช่น การประชุมเชิงปฏิบัติการ การสาธิตการปรุงอาหารเพื่อสุขภาพ และการจัดแคมเปญออนไลน์เพื่อกระตุ้นให้ผู้คนรับรู้ถึงประโยชน์ของการลดบริโภคเกลือ',
                'โครงการนี้มีความสำคัญในการลดความเสี่ยงของโรคหัวใจและหลอดเลือดซึ่งเป็นสาเหตุการตายอันดับต้น ๆ ของประชากรโลก การปรับปรุงคุณภาพชีวิตของประชาชนด้วยการส่งเสริมสุขภาพหัวใจที่ดีเป็นหนึ่งในเป้าหมายหลักของโครงการนี้ โรงพยาบาลรามาธิบดีได้เข้ามามีส่วนร่วมในการสนับสนุนโครงการนี้ โดยให้ข้อมูลทางการแพทย์และร่วมจัดกิจกรรมการศึกษาเพื่อเพิ่มความรู้ความเข้าใจของประชาชนเกี่ยวกับสุขภาพหัวใจและการบริโภคเกลืออย่างปลอดภัยในชีวิตประจำวัน',
            ],
            status: 'กำลังดำเนินการ',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2567,
                    title: "การวิจัยและพัฒนาข้อมูล",
                    detail: [
                      'เริ่มต้นการวิจัยเกี่ยวกับผลกระทบของการบริโภคเกลือต่อสุขภาพหัวใจและหลอดเลือด',
                    ],
                },
            ],
        },
        {
            image: 'dental',
            title: 'โครงการส่งเสริมการดูแลสุขภาพฟันในประชากรชาวชนบท',
            founder: ['รศ.ดร. วิภาวดี วรรณสุวรรณ'],
            description: [
                'โครงการนี้มุ่งเน้นการส่งเสริมสุขภาพฟันและการป้องกันโรคในประชากรที่อาศัยอยู่ในพื้นที่ชนบท ซึ่งมักขาดแคลนบริการทางทันตกรรมและการดูแลสุขภาพที่เหมาะสม นำโดย รศ.ดร. วิภาวดี วรรณสุวรรณ ทีมงานได้เริ่มต้นการวางแผนโครงการโดยสำรวจความต้องการด้านสุขภาพฟันในชุมชนชนบท และจัดเตรียมทรัพยากรที่จำเป็นสำหรับการดำเนินงาน โครงการนี้มีความสำคัญในการให้ความรู้แก่ประชาชนเกี่ยวกับการดูแลสุขภาพฟันและการรักษาสุขอนามัยในช่องปากที่ถูกต้อง',
                'หนึ่งในกิจกรรมสำคัญของโครงการคือการจัดกิจกรรมเชิงปฏิบัติการและให้บริการตรวจสุขภาพฟันฟรีแก่ชุมชนที่เข้าร่วม โดยมีการฝึกอบรมและให้คำแนะนำในการทำความสะอาดฟันและการป้องกันโรคฟันผุ นอกจากนี้ โครงการยังร่วมมือกับโรงเรียนในพื้นที่เพื่อส่งเสริมการดูแลสุขภาพฟันในเด็ก โดยมีการจัดกิจกรรมการเรียนรู้และเกมที่เกี่ยวข้องกับสุขภาพฟัน เพื่อเสริมสร้างความรู้และนิสัยที่ดีในการดูแลสุขภาพฟันให้กับเด็กตั้งแต่อายุยังน้อย',
                'หลังจากเสร็จสิ้นการดำเนินงานในระยะแรก โครงการได้ทำการประเมินผลการเปลี่ยนแปลงสุขภาพฟันในชุมชนที่เข้าร่วมและปรับปรุงโครงการตามผลการประเมินและข้อเสนอแนะจากชุมชน ปัจจุบันโครงการอยู่ระหว่างการขยายผลสู่พื้นที่ชนบทอื่นๆ เพื่อเพิ่มการเข้าถึงการดูแลสุขภาพฟันและยกระดับคุณภาพชีวิตของประชาชนในพื้นที่ห่างไกล ความร่วมมือนี้ยังรวมถึงการสนับสนุนจากโรงพยาบาลรามาธิบดีและหน่วยงานด้านสุขภาพอื่นๆ ในการจัดหาทรัพยากรและบุคลากรที่มีความเชี่ยวชาญในการดำเนินงานของโครงการ',
            ],
            status: 'เสร็จสิ้นการดำเนินงาน และอยู่ระหว่างการขยายผล',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2563,
                    title: "การวางแผนและการเตรียมการ",
                    detail: [
                        'เริ่มต้นการวางแผนโครงการและการจัดเตรียมทรัพยากรที่จำเป็น สำรวจความต้องการด้านสุขภาพฟันในชุมชนชนบทและกำหนดกลุ่มเป้าหมาย',
                    ],
                },
                {
                    year: 2564,
                    title: "การให้ความรู้และบริการตรวจสุขภาพฟัน",
                    detail: [
                        'จัดกิจกรรมเชิงปฏิบัติการเพื่อให้ความรู้เกี่ยวกับการดูแลสุขภาพฟันในชุมชน',
                    ],
                },
                {
                    year: 2568,
                    title: "ความร่วมมือกับโรงเรียนในพื้นที่",
                    detail: [
                        'ร่วมมือกับโรงเรียนเพื่อจัดกิจกรรมส่งเสริมสุขภาพฟันในเด็ก',
                    ],
                },
                {
                    year: 2566,
                    title: "การประเมินผลและการปรับปรุงโครงการ",
                    detail: [
                        'ติดตามและประเมินผลการเปลี่ยนแปลงสุขภาพฟันในชุมชนที่เข้าร่วมโครงการ ปรับปรุงและพัฒนาโครงการตามผลการประเมินและข้อเสนอแนะจากชุมชน',
                    ],
                },
                {
                    year: 2567,
                    title: "ขยายโครงการสู่พื้นที่ชนบทอื่นๆ",
                    detail: [
                        'ขยายโครงการไปยังพื้นที่ชนบทอื่นๆ เพื่อเพิ่มการเข้าถึงการดูแลสุขภาพฟัน',
                    ],
                },
            ],
        },
        {
            image: 'syringe',
            title: 'โครงการวิจัยการลดปริมาณขยะทางการแพทย์จากเข็มฉีด',
            founder: [
                'ผศ.ดร. ชัชวาล วงศ์กำแหง',
                'ดร. วรัญญู ศักดิ์ศรี',
            ],
            description: [
                'โครงการนี้มีวัตถุประสงค์ในการพัฒนากระบวนการและเทคโนโลยีที่สามารถลดปริมาณขยะทางการแพทย์ที่เกิดจากการใช้เข็มฉีด นำโดย ผศ.ดร. ชัชวาล วงศ์กำแหง และ ดร. วรัญญู ศักดิ์ศรี ทีมวิจัยได้เริ่มต้นด้วยการสำรวจและวิเคราะห์ปัญหาขยะทางการแพทย์จากเข็มฉีด โดยรวบรวมข้อมูลเกี่ยวกับวิธีการกำจัดขยะที่ใช้ในปัจจุบันและผลกระทบต่อสิ่งแวดล้อมที่เกิดขึ้น เพื่อระบุแนวทางที่สามารถลดปริมาณขยะและส่งเสริมความยั่งยืนในการจัดการขยะทางการแพทย์',
                'ในระยะต่อมา ทีมวิจัยได้มุ่งเน้นไปที่การพัฒนาเทคโนโลยีและวัสดุใหม่สำหรับเข็มฉีด เช่น เข็มฉีดที่สามารถใช้ซ้ำได้หรือวัสดุที่ย่อยสลายได้ การทดลองและประเมินประสิทธิภาพของวัสดุใหม่นี้จะช่วยในการตัดสินใจเลือกวิธีการที่เหมาะสมในการลดขยะ ทีมวิจัยยังได้ร่วมมือกับองค์กรต่างๆ และโรงพยาบาลในประเทศไทยเพื่อทดสอบและปรับปรุงการใช้งานจริง โครงการนี้มีความสำคัญในการลดผลกระทบต่อสิ่งแวดล้อมและเพิ่มความยั่งยืนในกระบวนการทางการแพทย์ ซึ่งสอดคล้องกับเป้าหมายของการพัฒนาที่ยั่งยืนในระดับโลก',
            ],
            status: 'กำลังดำเนินการ',
            url: [
                {
                    title: 'https://www.google.com/',
                    url: 'https://www.google.com/',
                },
            ],
            timeline: [
                {
                    year: 2565,
                    title: "การสำรวจและวิเคราะห์ปัญหา",
                    detail: [
                        'เริ่มต้นการสำรวจและวิเคราะห์ปัญหาขยะทางการแพทย์จากเข็มฉีด รวบรวมข้อมูลเกี่ยวกับวิธีการกำจัดขยะที่ใช้ในปัจจุบันและผลกระทบต่อสิ่งแวดล้อม',
                    ],
                },
                {
                    year: 2566,
                    title: "การพัฒนาเทคโนโลยีและวัสดุใหม่",
                    detail: [
                        'พัฒนาเทคโนโลยีและวัสดุใหม่สำหรับการลดปริมาณขยะจากเข็มฉีด ทดลองและประเมินประสิทธิภาพของเข็มฉีดที่สามารถใช้ซ้ำได้หรือวัสดุที่ย่อยสลายได้',
                    ],
                },
            ],
        },
    ];
    const [ db, setDB ] = useState([]);
    const [ favoriteItems, setFavoriteItems ] = useState([]);
    const [ items, setItems ] = useState([]);

    const [ search_text, setSearch_text ] = useState('');
    const [ filter_sort, setFilster_sort ] = useState('default');
    const [ filter_status, setFilter_status ] = useState('default');
    const ref_filter_opt = useRef(null);

    useEffect(() => {
        let abortcontroller = new AbortController();

        axios.get(php_list.php_testimonials_read_all, { signal: abortcontroller.signal })
        .then((response) => {
            // Handle the success response
            console.log(response.data);

            if (response.data.success) {
                let format_db = [...response.data.results];

                for(let i = 0; i < format_db.length; i++) {
                    let founder = format_db[i].founders.split(",");
                    format_db[i].founder = founder;

                    if(i === format_db.length - 1) {
                        setDB([...format_db]);

                        let favorite = [];
                        format_db.forEach((x, j) => {
                            if(x.favorite === '1') {
                                favorite.push(x);
                            }

                            if(j === format_db.length - 1) {
                                setFavoriteItems([...favorite]);
                            }
                        })
                    }
                }
            } 
            else {
                console.log('Error loading data: ' + response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        handle_filter();
    }, [search_text, filter_sort, filter_status]);

    useEffect(() => {
        //Set page
        //setItems_per_page((screen_width >= 1024 && screen_width < 1536) ? 9 : 8);
        setItems_per_page(21);

        const index_start = (page * items_per_page) - items_per_page; //Or (page * 7) - 7
        const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

        setItems(db?.slice(index_start, index_end));
        setMax_page(Math.ceil(db.length / items_per_page));

        //scroll to top of container
        document.getElementById('list_container').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

         //add animation into page
         animation_controls.start("hidden");

         setTimeout(() => {
             animation_controls.start("visible");
         }, 500);
    }, [page, db]);

    const Testimonial_items = ({image, title, founder}) => {
        const [ hover, setHover ] = useState(false);

        return (
            <div className='w-full h-fit cursor-pointer' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className='w-full h-fit flex justify-center items-center'>
                    <img src={image} className={'w-40 h-40 object-cover rounded-full transition duration-300 ' + (hover ? '-translate-y-3' : 'translate-y-0')}></img>
                </div>
                
                <p className={'notosan-semi mt-4 line-clamp-2 ' + (hover ? 'underline text-web-gold' : 'text-web-black')}>{title}</p>
                <p className={'notosan-reg text-web-black text-sm line-clamp-2 ' + (hover ? 'text-web-gold' : 'text-web-black')}>โดย {founder.join(', ')}</p>
            </div>
        )
    }

    async function handle_filter() {
        const search_result = await search_items();
        const filter_status_result = await filter_by_status(search_result);
        await sort_items(filter_status_result);

        function search_items() {
            return new Promise((resolve, reject) => {
                //hide all items first
                animation_controls.start("hidden");

                //normal filter process
                let new_result = [];

                if(search_text === '') {
                    new_result = [...db];
                }
                else {
                    for(let i = 0; i < items.length; i++) {
                        const project_title = items[i].title;
    
                        if(project_title.toLowerCase().includes(search_text)) {
                            new_result.push(items[i]);
                            continue;
                        }
    
                        const project_founder = [...items[i].founder];
                        for(let j = 0; j < project_founder.length; j++) {
                            const founder_name = project_founder[j];
    
                            if(founder_name.toLowerCase().includes(search_text)) {
                                new_result.push(items[i]);
                                break;
                            }
                        }
                    }
                }

                resolve(new_result);
            });
        }

        function filter_by_status(search_result) {
            return new Promise((resolve, reject) => {
                let new_result = [];

                if(filter_status === 'default') {
                    new_result = [...search_result];
                }
                else {
                    for(let i = 0; i < search_result.length; i++) {
                        const project_status = search_result[i].status;

                        if(project_status.toLowerCase().includes(filter_status)) {
                            new_result.push(search_result[i]);
                            continue;
                        }
                    }
                }
                
                resolve(new_result);
            });
        }

        function sort_items(result) {
            return new Promise((resolve, reject) => {
                let new_result = [...result];

                if(filter_sort === 'title_ascending') {
                    new_result = new_result.sort((a, b) => a.title.localeCompare(b.title));
                }
                else if(filter_sort === 'title_descending') {
                    new_result = new_result.sort((a, b) => b.title.localeCompare(a.title));
                }
                
                setItems(Array.isArray(new_result) ? [...new_result] : []);

                //set page back to page1
                setPage(1);
                const index_start = (page * items_per_page) - items_per_page; //Or (page * 7) - 7
                const index_end = (page * items_per_page > db.length) ? db.length : page * items_per_page;

                setItems(new_result?.slice(index_start, index_end));
                setMax_page(Math.ceil(db.length / items_per_page));

                //run animation of page change effect after finishing filter process
                setTimeout(() => {
                    animation_controls.start("visible");
                }, 500);
                resolve();
            });
        }
    }

    const animation_controls = useAnimationControls();
    const ani_obj = {
        hidden: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.2 }
        },
        visible: (index) => ({
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, delay: index * 0.1 }
        })
    }

    return (
        <div className='relative w-screen h-screen z-0'>
            { /* Content of Home */ }
            <div className='w-screen h-screen overflow-y-scroll'>
                <Header/>

                <div className='relative w-full h-60'>
                    <div className='absolute top-0 left-0 w-full h-14 bg-black'>
                        <div className='relative w-full h-full'>
                            <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center gap-5 z-20'>
                                <Link to={'/'}><p className='notosan-reg text-white ml-10 -translate-y-0.5 cursor-pointer'>หน้าแรก</p></Link>
                                <p className='notosan-reg text-white -translate-y-0.5'>/</p>
                                <p className='notosan-reg text-white -translate-y-0.5'>ผลงานของเรา</p>
                            </div>

                            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black opacity-80 z-10 py-3 px-4'></div>
                        </div>
                    </div>

                    <div className='absolute bottom-0 left-0 w-full h-4/6 bg-gradient-to-t from-black opacity-30 z-10'></div>
                    <img src={cover} className='relative w-full h-full object-cover'/>
                </div>

                {/* Display on large screen */}
                <div className='hidden lg:block mx-10 md:mx-20 xl:mx-32 pt-14 sm:pt-28'>
                    <div className='w-full h-fit flex flex-col justify-center my-6'>
                        <h3 className='text-web-gold text-3xl sm:text-4xl notosan-med text-center'>ผลงานของเรา</h3>
                        <img src={title_decorate} className='w-28 h-auto self-center my-4'/>
                    </div>

                    <TestimonialIntroduction db={db}/>

                    <div className='w-full h-fit flex flex-col mt-14 mb-8'>
                        <div className='w-full sm:w-fit h-fit flex gap-3 items-center justify-center sm:justify-start'>
                            <h3 className='text-web-gold text-2xl notosan-med'>ผลงานทั้งหมดของเรา</h3>
                            <div className='hidden sm:block w-20 h-0.5 bg-web-gold'></div>
                        </div>
                    </div>

                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        breakpoints={{
                             // when window width is >= 460px
                             460: {
                                spaceBetween: 10,
                                slidesPerView: 2,
                            },
                            // when window width is >= 640px
                            640: {
                                spaceBetween: 20,
                                slidesPerView: 3,
                            },
                            // when window width is >= 768px
                            768: {
                                spaceBetween: 20,
                                slidesPerView: 4,
                            },
                            // when window width is >= 1024px
                            1024: {
                                spaceBetween: 20,
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination, Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        loop={true}>
                        {
                            favoriteItems.map((x, i) => {
                                return (
                                    <SwiperSlide key={i} onClick={() => {
                                        setModal_detail(favoriteItems[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <HomeTestimonial_Card info={x} custom={0}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>

                {/* Display on mobile screen */}
                <div className='block lg:hidden mx-10 md:mx-20 xl:mx-32 pt-14 sm:pt-28'>
                    <TestimonialIntroduction db={db}/>

                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        breakpoints={{
                             // when window width is >= 460px
                             460: {
                                spaceBetween: 10,
                                slidesPerView: 2,
                            },
                            // when window width is >= 640px
                            640: {
                                spaceBetween: 20,
                                slidesPerView: 3,
                            },
                            // when window width is >= 768px
                            768: {
                                spaceBetween: 20,
                                slidesPerView: 4,
                            },
                            // when window width is >= 1024px
                            1024: {
                                spaceBetween: 20,
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true, dynamicBullets: true, }}
                        modules={[Pagination, Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        loop={true}>
                        {
                            favoriteItems.map((x, i) => {
                                return (
                                    <SwiperSlide key={i} onClick={() => {
                                        setModal_detail(favoriteItems[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <Testimonial_Card_Favorite info={x}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>

                <div className='mx-10 md:mx-20 xl:mx-32 pb-14 sm:pb-28'>
                    <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 gap-2 lg:flex lg:justify-between lg:items-end my-14 mb-10'>
                        <div className='w-full lg:w-96 h-fit'>
                            <SearchInput placeholder={'ค้นหาคำสำคัญ'} handle_search={(value) => { 
                                setSearch_text(value) 
                            }}/>
                        </div>
                        
                        <div className='w-fit h-fit grid grid-cols-2 lg:flex lg:justify-end gap-2'>
                            <select ref={ref_filter_opt} className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer'
                            onChange={(e) => {
                                setFilter_status(e.target.value);
                            }}>
                                <option value={'default'}>คัดกรองจากสถานะการดำเนินงาน...</option>

                                {
                                    status_list.map((x, i) => {
                                        return (
                                            <option key={i} value={x}>{x}</option>
                                        )
                                    })
                                }
                            </select>

                            <select className='notosan-reg px-4 py-1.5 rounded-full border border-gray-300 cursor-pointer'
                            onChange={(e) => {
                                setFilster_sort(e.target.value);
                            }}>
                                <option value={'default'}>จัดเรียงรายการ...</option>
                                <option value={'title_ascending'}>จัดเรียงตามหัวข้อ &#40;ก - ฮ&#41;</option>
                                <option value={'title_descending'}>จัดเรียงตามหัวข้อ &#40;ฮ - ก&#41;</option>
                            </select>
                        </div>
                    </div>

                    { /* Display on large screen */ }
                    <div id='list_container' className='hidden sm:grid w-full h-fit grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-7 gap-x-5 gap-y-10 my-10'>
                        {
                            items.map((x, i) => {
                                return (
                                    <motion.div key={i} className='w-full h-fit' 
                                    variants={ani_obj}
                                    initial={'hidden'}
                                    animate={animation_controls}
                                    custom={Math.ceil(i/7)}
                                    onClick={() => { 
                                        setModal_detail(items[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <Testimonial_items image={x.image} title={x.title} founder={x.founder}/>
                                    </motion.div>
                                )
                            })
                        }
                    </div>

                    { /* Display on mobile screen */ }
                    <div className='block sm:hidden my-8 sm:my-14'>
                        {
                            items.map((x, i) => {
                                return (
                                    <div key={i} className='my-6'
                                    onClick={() => {
                                        setModal_detail(items[i]);
                                        setModal_is_show(true);
                                    }}>
                                        <img src={x.image} className='w-full h-56 object-cover rounded-md'/>
                                        <p className='notosan-med text-web-black mt-4'>{x.title}</p>
                                        <p className='notosan-light text-web-black-secondary text-xs'>{x.founder}</p>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div className='w-full h-fit flex justify-end items-center gap-2'>
                        <Web_pagination current_page={page} max_page={max_page} 
                        handle_change_page={(x) => setPage(x)}/>
                    </div>
                </div>

                <Footer form={false}/>
            </div>

            { /* Any modal */ }
            <ModalTestimonial_detail isShow={modal_is_show} detail={modal_detail} handle_close={() => setModal_is_show(false)}/>
        </div>
    )
}

export default Testimonial