import React, { useEffect, useState } from 'react';
import CrossFadeImage from '../Components/Image/CrossFadeImage';

import intro1 from '../../assets/introduction/intro1.png';
import intro2 from '../../assets/introduction/intro2.png';
import intro3 from '../../assets/introduction/intro3.png';

import intro_mobile1 from '../../assets/introduction/intro_mobile1.png';
import intro_mobile2 from '../../assets/introduction/intro_mobile2.png';
import intro_mobile3 from '../../assets/introduction/intro_mobile3.png';

const HomeIntroduction = () => {
    const images_list = [
        intro1, 
        intro2, 
        intro3
    ];

    const mobile_images_list = [
        intro_mobile1, 
        intro_mobile2, 
        intro_mobile3
    ];

    const [ index, setIndex ] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % images_list.length);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className='hidden sm:block w-auto sm:w-full h-4/6 md:h-5/6'>
                <CrossFadeImage image1={images_list[(index - 1 < 0) ? images_list.length - 1 : index - 1]} image2={images_list[index]}/>
            </div>

            <div className='block sm:hidden w-full h-5/6'>
                <CrossFadeImage 
                image1={mobile_images_list[(index - 1 < 0) ? mobile_images_list.length - 1 : index - 1]} 
                image2={mobile_images_list[index]}
                image_properties={['object-top']}/>
            </div>
        </>
    )
}

export default HomeIntroduction