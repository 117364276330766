import React, { useState } from 'react';

const NewsCard = ({title, date, image, detail}) => {
    const [ hover, setHover ] = useState(false);

    const thaiMonths = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];
    const each_date = new Date(date);
    const each_dateD = each_date.getDate();
    const each_dateM = each_date.getMonth();
    const each_dateY = each_date.getFullYear();

    //This Card differs from AcitivityCard at image height, no detail added, and the way date presented
    //NewCard height is fixed at h-64 while AcitivityCard is auto
    
    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        className='w-full h-fit border border-gray-300 rounded-xl cursor-pointer'>
            <div className='w-full h-64 rounded-t-xl overflow-hidden'>
                <img src={image} className={'w-full h-full object-cover transition-transform duration-300 ' + (hover ? 'scale-125' : 'scale-100')}/>
            </div>

            <div className='w-fit h-fit px-8 pb-10'>
                <p className='notosan-reg text-web-black-secondary text-sm mt-6'>{each_dateD + ' ' + thaiMonths[each_dateM] + ' ' + (each_dateY + 543)}</p>
                <p className={'w-full h-fit notosan-med text-web-black text-base line-clamp-2 text-web-gold text-xl ' + (hover ? 'underline' : '')}>{title}</p>
                <p className='w-full h-fit notosan-reg text-web-black text-base line-clamp-5 mt-3'>{detail}</p>
            </div>
        </div>
    )
}

export default NewsCard