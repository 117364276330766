import React, { useEffect, useState } from 'react';

const CrossFadeImage = ({image1, image2, image_properties}) => {
  const [ show_image2, setShow_image2 ] = useState(false);
  const [ image1_src, setImage1_src ] = useState(image1);

  useEffect(() => {
      setShow_image2(true);

      setTimeout(() => {
          setImage1_src(image2);
          setShow_image2(false);
      }, 1000);
  }, [image2]);

  function getTailwind_image_properties() {
      let tailwind_image_property = '';

      image_properties?.map(x => {
          tailwind_image_property += ' ' + x + ' ';
      });

      return tailwind_image_property;
  }

  return (
    <div className='relative w-full h-full rounded-md'>
        { /* this z-50 div is used make right click image act as common web elements */ }
        <div className='absolute top-0 left-0 w-full h-full opacity-0 z-20'></div>

        <img className={'absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 z-10 ' + (show_image2 ? 'opacity-100 visible' : 'opacity-0 invisible')} src={image2}/>
        <img className={'absolute top-0 left-0 w-full h-full object-cover ' + getTailwind_image_properties()} src={image1_src}/>
    </div>
  )
}

export default CrossFadeImage